import { render, staticRenderFns } from "./PartnersImgSection.vue?vue&type=template&id=436841f9&scoped=true&"
var script = {}
import style0 from "@/styles/PartnersImgSection.css?vue&type=style&index=0&id=436841f9&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "436841f9",
  null
  
)

export default component.exports
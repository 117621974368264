import Vue from 'vue'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'
import '@mdi/font/css/materialdesignicons.css'

Vue.use(Vuetify);

//Configuração dos temas claro/escuro da página
export default new Vuetify({
    theme: {
        themes: {
            light: {
                primary: "#44B8E7",
                secondary: "#121945",
                green: "#9ACA3F",
                greensoft: "#F4F7F5",
            },
            dark: {
                primary: "#44B8E7",
                secondary: "#121945",
                green: "#9ACA3F",
                greensoft: "#F4F7F5",
            },
        }
    }
});
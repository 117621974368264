import bibtex from '@/assets/bibtex.js'

export default {
  
  data() {
    return {
      page: 1,
      perPage: 4,
      pagination: 3,
      dialog: false,
      
      // Atenção: O tipo de coclhete ou chave determina o tipo da variável
      bibtex: bibtex,
      bibtex_item: {},
      sliced: {},
      button: [
        { name: 'Conferência',
          color: '#9ACA3F',
        },
        { name: 'Mestrado',
          color: '#44B8E7',
        },
        { name: 'Periódico',
          color: '#121945',
        },
        { name: 'MBA',
          color: '#FF5252',
        },
        { name: 'PDF',
          color: '#FF2222',
        },
      ],
    }
  },

  methods: {

    get_btn_id(tag) {

      var index = 0;

      if (tag == "inproceedings")
      {
        index = 0;
      }
      if (tag == "article")
      {
        index = 2;
      }
      if (tag == "mastersthesis")
      {
        index = 1;
      }
      if (tag == "mbathesis")
      {
        index = 3;
      }
      if (tag == "pdf")
      {
        index = 4;
      }
      return index;
    },

    open_bibtex(item_id) {
      this.dialog = true;
      var item = this.bibtex.find(element => element.id == item_id);
      this.bibtex_item = item;
    },

    download (URL) {
      const url = URL;
      window.open(url, '_blank');
    }

  },
  
};

const bibtex = [
    {
        "id": "1",
        "text": "Dantas, Joao P. A. and Geraldo, Diego and Medeiros, Felipe L. L. and Maximo, Marcos R. O. A. and Yoneyama, Takashi. <strong>Real-Time Surface-to-Air Missile Engagement Zone Prediction Using Simulation and Machine Learning</strong>. In <em>Proceedings of Interservice/Industry Training, Simulation and Education Conference (I/ITSEC)</em>, FL, USA, 2023.",
        "bibtex": "@inproceedings{dantas2023real, \n author={Dantas, Joao P. A. and Geraldo, Diego and Medeiros, Felipe L. L. and Maximo, Marcos R. O. A. and Yoneyama, Takashi},\n title={{Real-Time Surface-to-Air Missile Engagement Zone Prediction Using Simulation and Machine Learning}},\n year={2023},\n booktitle={Interservice/Industry Training, Simulation and Education Conference (I/ITSEC)}, \n pages={Orlando, FL, USA}\n}",
        "tag": "inproceedings",
        "pdf": "https://arxiv.org/pdf/2311.11905"
    },
    {
        "id": "2",
        "text": "Geraldo, Diego. <strong>Inovação Aberta Aplicada ao Ciclo de Vida do Ambiente de Simulação Aeroespacial: Um Estudo de Viabilidade </strong>. In <em> Fundação Getúlio Vargas</em>, 2023.",
        "bibtex": "@inproceedings{diego2023viabilidade, \n author={Geraldo, Diego},\n title={{Inovação Aberta Aplicada ao Ciclo de Vida do Ambiente de Simulação Aeroespacial: Um Estudo de Viabilidade}},\n year={2023},\n type = {MBA's Thesis},\nschool = {Fundação Get\\'{u}lio Vargas},\naddress = {São Paulo, SP, Brazil},\n url={https://zenodo.org/records/10059896?token=eyJhbGciOiJIUzUxMiJ9.eyJpZCI6IjgwN2UwMzA2LWIwYTktNDY3NC05ZTM2LTk0MGVkNGNjNzgwMiIsImRhdGEiOnt9LCJyYW5kb20iOiJmM2I0ZmI3NTMyOTcxNjMxYTNiMWQ4YTg1NzdiNTRhMyJ9.DqMlYBlbjLeRuXBcHMCFpdK-9aIyl4T24yapuYSRx4kljGbU_CnIOFVJs-pff_w5FrANrFwtcMeKUVC-BKf5bA}}",
        "tag": "mbathesis",
        "pdf": ""
    },
    {
        "id": "3",
        "text": "Joao P. A. Dantas and Diego Geraldo and Andre N. Costa and Marcos R. O. A. Maximo and Takashi Yoneyama. <strong>ASA-SimaaS: Advancing Digital Transformation through Simulation Services in the Brazilian Air Force</strong>. In <em>Simpósio de Aplicações Operacionais em Áreas de Defesa 2023 (SIGE2023)</em>, Setembro de 2023.",
        "bibtex": "@inproceedingsdantas2023asasimaas, \n author={Joao P. A. Dantas and Diego Geraldo and Andre N. Costa and Marcos R. O. A. Maximo and Takashi Yoneyama},\n title={ASA-SimaaS: Advancing Digital Transformation through Simulation Services in the Brazilian Air Force}, \nyear = {2023},\nmonth = {09},\nbooktitle = {Simp\\'{o}sio de Aplica\\c{c}\\~{o}es Operacionais em \\'{A}reas de Defesa 2023 (SIGE2023)\n}",
        "tag": "inproceedings",
        "pdf": ""
    },
    {
        "id": "4",
        "text": "Joao P. A. Dantas, Marcos R. O. A. Maximo, Takashi Yoneyama. <strong>Autonomous Agent for Beyond Visual Range Air Combat: A Deep Reinforcement Learning Approach</strong>. In <em>Proceedings of the 2023 ACM SIGSIM Conference on Principles of Advanced Discrete Simulation (SIGSIM-PADS '23)</em>, pages 25th-28th, Orlando, FL, USA, 2023.",
        "bibtex": "@inproceedings{dantas2023autonomous,\n    author = {Dantas, Joao P. A. and Maximo, Marcos R. O. A. and Yoneyama, Takashi},\n    title = {Autonomous Agent for Beyond Visual Range Air Combat: A Deep Reinforcement Learning Approach},\n    year = {2023},\n    isbn = {979-8-4007-0030},\n    publisher = {Association for Computing Machinery},\n    address = {Orlando, FL, USA},\n    url = {https://doi.org/10.1145/3573900.3593631},\n    doi = {10.1145/3573900.3593631},\n    booktitle = {Proceedings of the 2023 ACM SIGSIM Conference on Principles of Advanced Discrete Simulation},\n    location = {Orlando, FL, USA},\n    series = {SIGSIM-PADS '23}\n}",
        "tag": "inproceedings",
        "pdf": ""
    },
    {
        "id": "5",
        "text": "Joao P. A. Dantas, Andre N. Costa, Vitor C. F. Gomes, Andre R. Kuroswiski, Felipe L. L. Medeiros, Diego Geraldo. <strong>ASA: A Simulation Environment for Evaluating Military Operational Scenarios</strong>. In <em>Proceedings of the 20th International Conference on Scientific Computing</em>, pages 25th-28th, Las Vegas, NV, USA, 2022.",
        "bibtex": "@INPROCEEDINGS{dantas2022asa,\n    author={Dantas, Joao P. A and Costa, Andre N. and Gomes, Vitor C. F. and Kuroswiski, Andre R. and Medeiros, Felipe L. L. and Geraldo, Diego},\n    title={{ASA: A Simulation Environment for Evaluating Military Operational Scenarios}},\n    year={2022},\n    booktitle={Proceedings of the 20\\textsuperscript{th} International Conference on Scientific Computing},\n    pages={25\\textsuperscript{th}-28\\textsuperscript{th}, Las Vegas, NV, USA}\n}",
        "tag": "inproceedings",
        "pdf": ""
    },
	{
        "id": "6",
        "text": "Andre Kuroswiski, Diego Geraldo, Andre Costa, Angelo Passaro. <strong>Modelos Alternativos para Predição do Alcance Máximo de Mísseis Ar-Ar em Simulações de Alta Fidelidade</strong>. In <em>Simpósio de Aplicações Operacionais em Áreas de Defesa 2022 (SIGE2022)</em>, Setembro de 2022.",
        "bibtex": "@inproceedings{kuroswiski2022modelos,\nauthor = {Kuroswiski, Andr\\'e and Geraldo, Diego and Costa, Andr\\'e and Passaro, Angelo},\nyear = {2022},\nmonth = {09},\npages = {},\nbooktitle = {Simp\\'{o}sio de Aplica\\c{c}\\~{o}es Operacionais em \\'{A}reas de Defesa 2022 (SIGE2022)},\ntitle = {Modelos Alternativos para Predi\\c{c}\\~{a}o do Alcance M\\'aximo de M\\'isseis Ar-Ar em Simula\\c{c}\\~{o}es de Alta Fidelidade},\nkeywords = {Simula\\c{c}\\~{a}o, Combate A\\'ereo, M\\'issil Ar-Ar, Regress\\~{a}o Polinomial}\n}",
        "tag": "inproceedings",
        "pdf": ""
    },
    {
        "id": "7",
        "text": "Geraldo Mulato De Lima Filho, Andre Rossi Kuroswiski, Felipe Leonardo Lobo Medeiros, Mark Voskuijl, Herman Monsuur, Angelo Passaro. <strong>Optimization of Unmanned Air Vehicle Tactical Formation in War Games</strong>. <em>IEEE Access</em>, 10: 21727-21741, 2022.",
        "bibtex": "@ARTICLE{limafilho2022optimization,\n  author={De Lima Filho, Geraldo Mulato and Kuroswiski, Andr\\'e Rossi and Medeiros, Felipe Leonardo L\\^obo and Voskuijl, Mark and Monsuur, Herman and Passaro, Angelo},\n  journal={IEEE Access}, \n  title={Optimization of Unmanned Air Vehicle Tactical Formation in War Games}, \n  year={2022},\n  volume={10},\n  number={},\n  pages={21727-21741},\n  doi={10.1109/ACCESS.2022.3152768}}",
        "tag": "article",
        "pdf": ""
    },
    {
        "id": "8",
        "text": "Joao P. A. Dantas, Andre N. Costa, Felipe L. L. Medeiros, Diego Geraldo, Marcos R. O. A. Maximo, Takashi Yoneyama. <strong>Supervised Machine Learning for Effective Missile Launch Based on Beyond Visual Range Air Combat Simulations</strong>. In <em>Proceedings of the Winter Simulation Conference</em>, Singapore, 2022.",
        "bibtex": "@inproceedings{dantas2022supervised,<br />&nbsp;&nbsp;&nbsp;&nbsp;author={Dantas, Joao P. A. and Costa, Andre N. and Medeiros, Felipe L. L. and Geraldo, Diego and Maximo, Marcos R. O. A. and Yoneyama, Takashi},<br />&nbsp;&nbsp;&nbsp;&nbsp;title={{Supervised Machine Learning for Effective Missile Launch Based on Beyond Visual Range Air Combat Simulations}},<br />&nbsp;&nbsp;&nbsp;&nbsp;year={2022},<br />&nbsp;&nbsp;&nbsp;&nbsp;booktitle={Proceedings of the Winter Simulation Conference},<br />&nbsp;&nbsp;&nbsp;&nbsp;location={Singapore},<br />&nbsp;&nbsp;&nbsp;&nbsp;series={WSC '22}<br />}",
        "tag": "inproceedings",
        "pdf": ""
    },
    {
        "id": "9",
        "text": "Joao P. A. Dantas, Marcos R. O. A. Maximo, Andre N. Costa, Diego Geraldo, Takashi Yoneyama. <strong>Machine Learning to Improve Situational Awareness in Beyond Visual Range Air Combat</strong>. <em>IEEE Latin America Transactions</em>, 20(8), 2022.",
        "bibtex": "@article{dantas2022machine,<br />&nbsp;&nbsp;&nbsp;&nbsp;title={{Machine Learning to Improve Situational Awareness in Beyond Visual Range Air Combat}},<br />&nbsp;&nbsp;&nbsp;&nbsp;volume={20},<br />&nbsp;&nbsp;&nbsp;&nbsp;url={https://latamt.ieeer9.org/index.php/transactions/article/view/6530},<br />&nbsp;&nbsp;&nbsp;&nbsp;journal={IEEE Latin America Transactions},<br />&nbsp;&nbsp;&nbsp;&nbsp;author={Dantas, Joao P. A. and Maximo, Marcos R. O. A. and Costa, Andre N. and Geraldo, Diego and Yoneyama, Takashi},<br />&nbsp;&nbsp;&nbsp;&nbsp;year={2022},<br />&nbsp;&nbsp;&nbsp;&nbsp;number={8}<br />}",
        "tag": "article",
        "pdf": ""
    },
	{
		"id": "10",
		"text": "Andre N. Costa, Felipe L. L. Medeiros, Joao P. A. Dantas, Diego Geraldo, Nei Y. Soma. <strong>Formation Control Method Based on Artificial Potential Fields for Aircraft Flight Simulation</strong>. <em>SIMULATION SAGE</em>, 98(7):575-595, 2022.",
		"bibtex": "@article{costa2021formation,\n    author = {Andre N. Costa and Felipe L. L. Medeiros and Joao P. A. Dantas and Diego Geraldo and Nei Y. Soma},\n    title ={Formation Control Method Based on Artificial Potential Fields for Aircraft Flight Simulation},\n    journal = {SIMULATION},\n    volume = {98},\n    number = {7},\n    pages = {575-595},\n    year = {2022},\n    doi = {10.1177/00375497211063380},\n    URL = {https://doi.org/10.1177/00375497211063380},\n    eprint = {https://doi.org/10.1177/00375497211063380}\n}",
		"tag": "article",
        "pdf": ""
	},
	{
		"id": "11",
		"text": "Joao P. A. Dantas, Andre N. Costa, Diego Geraldo, Marcos R. O. A. Maximo, Takashi Yoneyama. <strong>Weapon Engagement Zone Maximum Launch Range Estimation Using a Deep Neural Network</strong>. <em>Intelligent Systems</em>, pages 193-207. Springer, Cham, 2021.",
		"bibtex": "@inproceedings{dantas2021weapon,\n    author=\"Dantas, Joao P. A.\n    and Costa, Andre N.\n    and Geraldo, Diego\n    and Maximo, Marcos R. O. A.\n    and Yoneyama, Takashi\",\n  title={Weapon Engagement Zone Maximum Launch Range Estimation Using a Deep Neural Network},\n    booktitle=\"Intelligent Systems\",\n    year=\"2021\",\n    publisher=\"Springer\",\n    address=\"Cham\",\n    pages=\"193--207\",\n    isbn=\"978-3-030-91699-2\"\n}",
		"tag": "inproceedings",
        "pdf": ""
	},
	{
		"id": "12",
		"text": "Joao P. A. Dantas, Andre N. Costa, Diego Geraldo, Marcos R. O. A. Maximo, Takashi Yoneyama. <strong>Engagement Decision Support for Beyond Visual Range Air Combat</strong>. In <em>Proceedings of the 2021 Latin American Robotics Symposium</em>, October 11th-15th, pp. 96-101, 2021.",
		"bibtex": "@inproceedings{dantas2021engagement,\n  title={Engagement Decision Support for Beyond Visual Range Air Combat},\n  author={Dantas, J. P. A. and Costa, A. N. and Geraldo, Diego and Maximo, M R O A and Yoneyama, Takashi},\n  booktitle={Proceedings of the 2021 Latin American Robotics Symposium, 2021 Brazilian Symposium on Robotics, and 2021 Workshop on Robotics in Education},\n  pages={October 11\\textsuperscript{th}-15\\textsuperscript{th}, 96-101},\n  year={2021}\n}",
		"tag": "inproceedings",
        "pdf": ""
	},
	{
		"id": "13",
		"text": "Geraldo Mulato De Lima Filho, Felipe Leonardo Lobo Medeiros, Angelo Passaro. <strong>Decision Support System for Unmanned Combat Air Vehicle in Beyond Visual Range Air Combat Based on Artificial Neural Networks</strong>. <em>Journal of Aerospace Technology and Management</em>, vol. 13, pp. e3721, 2021.",
		"bibtex": "@article{limafilho2021decision,  \ntitle={Decision Support System for Unmanned Combat Air Vehicle in Beyond Visual Range Air Combat Based on Artificial Neural Networks},  \nvolume={13},  ISSN={2175-9146},  \nurl={https://doi.org/10.1590/jatm.v13.1228},  \nDOI={10.1590/jatm.v13.1228},  journal={Journal of Aerospace Technology and Management},  \npublisher={Departamento de Ciência e Tecnologia Aeroespacial},  \nauthor={Lima Filho, Geraldo Mulato de and Medeiros, Felipe Leonardo Lôbo and Passaro, Angelo},  \nyear={2021},  \npages={e3721} }",
		"tag": "article",
        "pdf": ""
	},
	{
		"id": "14",
		"text": "Andre Rossi Kuroswiski. <strong>Modelagem e Simulação Baseada em Agentes como Ferramenta de Apoio à Avaliação de Capacidades de Defesa Aérea</strong>. <em>Master's thesis</em>, Instituto Tecnológico de Aeronáutica, São José dos Campos, SP, Brazil, 2020.",
		"bibtex": "@MastersThesis{kuroswiski2020,<br />&nbsp;&nbsp;&nbsp;&nbsp;author = {Kuroswiski, André Rossi},<br />&nbsp;&nbsp;&nbsp;&nbsp;title = \"{Modelagem e Simulação Baseada em Agentes como Ferramenta de Apoio à Avaliação de Capacidades de Defesa Aérea}\",<br />&nbsp;&nbsp;&nbsp;&nbsp;school = {Instituto Tecnol\\'{o}gico de Aeron\\'{a}utica},<br />&nbsp;&nbsp;&nbsp;&nbsp;year = {2020},<br />&nbsp;&nbsp;&nbsp;&nbsp;type = \"{Master's Thesis}\",<br />&nbsp;&nbsp;&nbsp;&nbsp;address = {S\\~{a}o Jos\\'{e} dos Campos, SP, Brazil}<br />}",
		"tag": "mastersthesis",
        "pdf": ""
	},
	{
		"id": "15",
		"text": "Andre N. Costa. <strong>Sequential Optimization of Formation Flight Control Method Based on Artificial Potential Fields</strong>. <em>Master's thesis</em>, Instituto Tecnológico de Aeronáutica, São José dos Campos, SP, Brazil, 2019.",
		"bibtex": "@MastersThesis{costa2019,<br />&nbsp;&nbsp;&nbsp;&nbsp;author = {André N. Costa},<br />&nbsp;&nbsp;&nbsp;&nbsp;title = \"{Sequential Optimization of Formation Flight Control Method Based on Artificial Potential Fields}\",<br />&nbsp;&nbsp;&nbsp;&nbsp;school = {Instituto Tecnol\\'{o}gico de Aeron\\'{a}utica},<br />&nbsp;&nbsp;&nbsp;&nbsp;year = {2019},<br />&nbsp;&nbsp;&nbsp;&nbsp;type = \"{Master's Thesis}\",<br />&nbsp;&nbsp;&nbsp;&nbsp;address = {S\\~{a}o Jos\\'{e} dos Campos, SP, Brazil}<br />}",
		"tag": "mastersthesis",
        "pdf": ""
	},
	{
        "id": "16",
        "text": "Joao P. A. Dantas. <strong>Apoio à decisão para o combate aéreo além do alcance visual: uma abordagem por redes neurais artificiais</strong>. <em>Master's thesis</em>, Instituto Tecnológico de Aeronáutica, São José dos Campos, SP, Brazil, 2018.",
        "bibtex": "@mastersthesis{dantas2018,<br />&nbsp;&nbsp;&nbsp;&nbsp;address = {S\\~{a}o Jos\\'{e} dos Campos, SP, Brazil},<br />&nbsp;&nbsp;&nbsp;&nbsp;school = {Instituto Tecnol\\'{o}gico de Aeron\\'{a}utica},<br />&nbsp;&nbsp;&nbsp;&nbsp;author = {Jo\\~{a}o P. A. Dantas},<br />&nbsp;&nbsp;&nbsp;&nbsp;title = {Apoio \\`{a} decis\\~{a}o para o combate a\\'{e}reo al\\'{e}m do alcance visual: uma abordagem por redes neurais artificiais.},<br />&nbsp;&nbsp;&nbsp;&nbsp;year = {2018},<br />&nbsp;&nbsp;&nbsp;&nbsp;type = {Master's Thesis}<br />}",
        "tag": "mastersthesis",
        "pdf": ""
    }
]

export default bibtex;
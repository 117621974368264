import axios from 'axios';
import eventBus from './EventBus';
import AlertSignIn from '@/components/AlertSignIn.vue'
import AlertLogin from '@/components/AlertLogin.vue'

export default {
  name: "App",
  components: {
    AlertSignIn,
    AlertLogin,
  },

  created() {
    /* Desabilitado pois a princípio não terá mais o botão que leva aos Serviços */
    //Apenas ao carregar a página, verifica se o provedor de OAuth está disponível
    //Isso significa que a página de serviços também estará
    //this.oauthProviderAvailable();
    eventBus.$on('checkAndRedirect', this.handleCheckAndRedirect);

  },

  data() {
    return {

      //Largura da janela do navegador
      windowWidth: window.innerWidth,

      //Se o menu lateral deve estar aberto ou não (habilitado em telas menores)
      openMenu: false,

      /* Desabilitado pois a princípio não terá mais o botão que leva aos Serviços */
      //Se o botão que leva à página de serviços está disponível ou não
      //servicesAvailable: false,

      //Um link para a página privada do portal, onde estão os links dos serviços
      privatePageLink: `${process.env.VUE_APP_PRIVATE_PAGE_LINK}`,
      VPNMessage: false,

      showTransLateMessage: false,
      enteringLabel: "Entrar",
      defaultLanguageCode: "pt",
      defaultPageLanguageCode: "pt",
      languagess: [
        {
          code: "pt",
          name: "Português",
          cname: "Português",
          ename: "Português",
        },
        {
          code: "en",
          name: "English",
          cname: "English",
          ename: "English",
        },
      ],
    };
  },

  mounted() {
    //Acompanha o redimensionamento da janela, esse valor é usado para tornar a página mais reativa
    window.addEventListener('resize', () => {
      this.windowWidth = window.innerWidth
    })

    setTimeout(() => {
      const testSourceLanguageEl = this.$refs.testSourceLanguage;
      //console.log(testSourceLanguageEl);
      if (testSourceLanguageEl != undefined) {
        if (testSourceLanguageEl.classList.contains("notranslate")) {
          testSourceLanguageEl.classList.remove("notranslate");
        }
      }
    }, 2000);

  },

  computed: {
    //Mostra os botões de navegação na parte superior somente em telas grandes
    showNavigationButtons() {
      return this.windowWidth > 1260;
    },
    //Telas menores irão apresentar um menu lateral
    showDrawerButton() {
      return this.windowWidth < 1260;
    },
    //Se a tela for muito pequena nem exibirá o nome na parte de cima
    showDisplayName() {
      return this.windowWidth > 630;
    }
  },

  methods: {
    handleCheckAndRedirect() {
      this.checkAndRedirect()

    },
    checkAndRedirect() {
      eventBus.$emit('overlay', true);
      var url = this.privatePageLink
      axios.get(url, { timeout: 1200 })  // Use HEAD request to get only the headers, not the page content
        .then((response) => {
          if (response.status === 200) {
            eventBus.$emit('overlay', false);
            this.redirectTo(url)
          }
          else {
            eventBus.$emit('overlay', false);
            this.$refs.alertLogin.open("Falha na conexão", "Por favor, verifique sua conexão VPN. <br>Se você ainda não está registrado em nosso serviço, entre em contato com um administrador para verificar a possibilidade de se inscrever.")
          }
        })
        .catch((error) => {
          eventBus.$emit('overlay', false);
          this.$refs.alertLogin.open("Falha na conexão", "Por favor, verifique sua conexão VPN. <br>Se você ainda não está registrado em nosso serviço, entre em contato com um administrador para verificar a possibilidade de se inscrever.")
          console.log("timeout", error)
        });
    },

    signIn() {
      this.$refs.alertDialog.open("Entre em contato!", "Nosso serviço está disponível para parceiros. <br>Se você ainda não está registrado, entre em contato com um administrador para verificar a possibilidade de se inscrever.")

    },

    //Método que redireciona para um link qualquer
    //Abre na MESMA página
    redirectTo(link) {
      //Troca a URL da página atual
      window.location.href = link;
    },

    languageSelectedHandler(info) {
      if(info.code != this.defaultLanguageCode) {
        this.enteringLabel = "Login";
        this.showTransLateMessage = true;         
      } else {
        this.enteringLabel = "Entrar";
        this.showTransLateMessage = false;
      }
      //console.log(info);
    },

    googleTranslateSelectedHandler(language) {
      console.log(language);
      //const { code, name, cname, ename } = language
      // todo ...
    },

    //
    //Como verificar se o usuário está na VPN ou não? Javascript não suporta pings,
    //mas o que dá pra fazer é verificar se página do OAuth está acessível e, se estiver,
    //então sabemos que estará na VPN.
    //

    /* Desabilitado pois a princípio não terá mais o botão que leva aos Serviços */

    /*oauthProviderAvailable(){
 
      //Uma interface para javascript interagir com servidores
      var xhr = new XMLHttpRequest();
 
      //Adicionamos uma função para executar ao sinal da conexão
      xhr.onreadystatechange = function () {
 
        //Se a resposta foi positiva
        if (xhr.readyState == 4 && xhr.status == 200) {
 
          //Habilita o botão que leva à página de serviços, que terá um login
          this.servicesAvailable = true;
 
        }
      }.bind(this)
 
      //Aqui de fato tentamos a conexão, é espero que ocorra um erro
      //caso não esteja conectado na VPN (irá dispar a funcitona definida acima)
       
      //Abrimos a conexão, o parâmetro true no final diz que é assíncrono
      xhr.open("GET", process.env.VUE_APP_CREDENTIALS_LINK_ADMIN, true)
      
      //Inicia a conexão, tentando fazer um envio, o que aciona a function 
      xhr.send(null);
 
 
    }*/

  },


};

import NavigationSection from "./NavigationSection.js"
import eventBus from './EventBus';
export default {
    mixins: [NavigationSection],

  
    data: () => ({
        alertDialog: false,
        alertResolve: null,
        alertReject: null,
        alertMessage: null,
        alertTitle: null,
        refsAlertLogin: null,
    }),

    mounted(){
        // Um listener especial que monitora eventos acionados por botão (sem clique)
        // No momento usado aqui para o caso de apertar "Enter"
        document.addEventListener("keydown", this.AlertShortCutHandler);
      },
  
      // Métodos executados logo antes da aplicação ser encerrada
      // Se tiver que liberar memória, é aqui
      beforeDestroy() {
        // Removemos o listener aqui, dizem que ajuda a evitar vazamento de memória
        document.removeEventListener("keydown", this.AlertShortCutHandler);
      },

    methods: {

        open(title, message) {

            this.alertDialog = true;
            this.alertTitle = title;
            this.alertMessage = message;
  
            return new Promise((resolve, reject) => {
              this.alertResolve = resolve;
              this.alertReject = reject;
            });
  
        },
        agree() {
            this.alertResolve(true);
            this.alertDialog = false;
            eventBus.$emit('checkAndRedirect')
        },

        cancel() {
            this.alertResolve(false);
            this.alertDialog = false;
        },

        /**
         * Tratamento de casos especiais que não envolvem clique no mapa (Ctrl+S, Ctrl+C, outros)
         *
         * @param {Object} e Um evento 
         * @returns None
         */
        AlertShortCutHandler(e){

        // Optei por favor esse handler separado para o caso do "Enter"
        // Como no "Alert" não há um form nem campos de input, não consegui usar o "submit"
            if (e.key === 'Enter'){
                //Confere se o dialog está aberto
                if(this.alertDialog){
                    this.agree()
                }
            }
        },
    },
};
import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import VideoBackground from 'vue-responsive-video-background-player'
import axios from 'axios'
import VGoogleTranslate from "v-google-translate";

Vue.prototype.$axios = axios;

//Esse component é necessário para poder user <video-background> em arquivos .vue
//Assim como usaria um <v-list>, <v-treeview> etc
Vue.component('video-background', VideoBackground);

//Vue.config.productionTip = false;
Vue.use(VGoogleTranslate)

new Vue({
  vuetify,
  axios,
  render: h => h(App)
}).$mount('#app')

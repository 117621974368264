import eventBus from './EventBus';

export default {

    created(){
        eventBus.$on('overlay', this.handleOverlay);
  
    },
  
    data: () => ({

      overlay: false,
    }),

    methods: {
        handleOverlay(data){
            this.overlay = data
        }

    },
};
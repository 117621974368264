
export default {
    data: () => ({
        slidePictures: [
            {
                src: require('@/assets/img/TVSite.png'),
                textTitle: "Serviço de Simulação",
                textBody: "Infraestrutura de computação em nuvem para execução de \
                lotes de simulações de forma paralela.",
            },
            {
                src: require('@/assets/img/WSSite.png'),
                textTitle: "Webstation",
                textBody: "Interface web para criação, configuração e \
                visualização das simulações.",
            },
            {
                src: require('@/assets/img/APSite.png'),
                textTitle: "AsaPy",
                textBody: "Biblioteca em Python para processamento e análise \
                dos resultados de simulações.",
            }
        ],
    }),
    methods: {
  
    }
  };
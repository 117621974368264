import { render, staticRenderFns } from "./ArticlesSection.vue?vue&type=template&id=dc637a58&scoped=true&"
import script from "@/scripts/ArticlesSection.js?vue&type=script&lang=js&"
export * from "@/scripts/ArticlesSection.js?vue&type=script&lang=js&"
import style0 from "@/styles/ArticlesSection.css?vue&type=style&index=0&id=dc637a58&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dc637a58",
  null
  
)

export default component.exports
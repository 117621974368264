import NavigationSection from "@/components/NavigationSection";
import HeroSection from "@/components/HeroSection";
import AboutSection from "@/components/AboutSection";
import FeaturesSection from "@/components/FeaturesSection";
import PartnersTxtSection from "@/components/PartnersTxtSection";
import PartnersImgSection from "@/components/PartnersImgSection";
import ArticlesSection from "@/components/ArticlesSection";
import ContactSection from "@/components/ContactSection";
import FooterSection from "@/components/FooterSection";
import OverlayComp from "@/components/OverlayComp";

export default {
  name: "PortalLanding",

  beforeCreate() {
    document.title = "Portal ASA - Ambiente de Simulação Aeroespacial";
  },

  data: () => ({

  }),

  components: {
    OverlayComp,
    NavigationSection,
    HeroSection,
    AboutSection,
    FeaturesSection,
    PartnersTxtSection,
    PartnersImgSection,
    ArticlesSection,
    ContactSection,
    FooterSection
  },

}
